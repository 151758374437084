import React from "react"
import Layout from "../../layouts/Layout"
import styled from "styled-components"

const StyleAboutContainer = styled.div`
  animation-duration: 2s;
  max-width: 1024px;
  margin: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    padding: 0;
  }
`
const StyledAboutTitle = styled.h1`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 42px;
  font-weight: 800;
  text-decoration: none;
  margin-top: 1rem;
`

const StyledAboutSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.paragraph};
  font-size: 18px;
`

const StyledAboutContent = styled.div`
  color: ${({ theme }) => theme.colors.paragraph};
  h2 {
    margin-top: 24px;
    font-family: ${({ theme }) => theme.family.kowaski};
    font-size: 30px;
  }
`

const AboutPage = () => {
  const metadata = {
    title: "About me · Nicolás Díaz",
    description:
      "I love to bring to life the ideas that can be realized through technology, so my interest is focused on designing and programming interfaces, always oriented to maintain a balance between beauty and functionality.",
    cover: "/images/covers/about_cover.png",
    type: "website",
  }

  return (
    <Layout metadata={metadata}>
      <StyleAboutContainer>
        <StyledAboutTitle>About Nicolás.</StyledAboutTitle>
        <StyledAboutContent>
          <StyledAboutSubtitle>
            To take care of the different aspects in the construction of
            technological products, I have gathered multiple experiences as a
            designer and developer, which have allowed me to see the complete
            panorama from how an interface looks, to how it results in the
            experience with users. The path of my work involves listening to the
            idea, determining its feasibility, generating a first design,
            establishing a prototype, developing and programming it.{" "}
          </StyledAboutSubtitle>
          <StyledAboutContent>
            <h2>What I Do</h2>
            <p>
              I love to bring to life the ideas that can be realized through
              technology, so my interest is focused on designing and programming
              interfaces, always oriented to maintain a balance between beauty
              and functionality.
            </p>
            <h2>My skills</h2>
            <p>
              Currently my skills focus on full stack javascript programming
              with tools like reactjs, nestjs and also creating npm packages.
              Regarding my design skills, I have a list of projects in my
              portfolio focused on user interfaces of real-life projects where I
              demonstrate my knowledge. Right now my favorite programs are Adobe
              XD to make applications and After Effect to animate some of my
              creations.
            </p>
          </StyledAboutContent>
        </StyledAboutContent>
      </StyleAboutContainer>
    </Layout>
  )
}

export default AboutPage
